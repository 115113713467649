import "./ListClass.css";
import MenuBar from "./MenuBar";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

export default function ListClassDB() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/kelas`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': Cookies.get('token'),
          },
          withCredentials: true, 
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setRows(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  
  const handleAddClass = async (newClass) => {
    if (!newClass) {
      console.error("Please enter a new period");
      return;
    }
    

    try {
      const response = await fetch(`${apiUrl}/kelas`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nama_kelas: newClass,
          regis_status: false,
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to add class: ' + response.statusText);
      }
      const addedClass = await response.json(); 
      setRows([...rows, addedClass]);
    } catch (error) {
      console.error('Error adding class:', error);
    } finally {
      window.location.reload();
      setNewClass("");
    }
  };


  const updateDataOpen = async (id) => {
    try {
      await fetch(`${apiUrl}/periode/active/${id}`, {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
          'Content-Type': 'application/json',
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Terjadi kesalahan saat memperbarui data:', error);
    }
  };

  const updateDataClose = async (id) => {
    try {
      await fetch(`${apiUrl}/periode/close/${id}`, {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
          'Content-Type': 'application/json',
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Terjadi kesalahan saat memperbarui data:', error);
    }
  };

  const exportData = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/export/${id}`, {
          method: 'GET',
          headers: {
              'Authorization': Cookies.get('token'),
          },
      });

      if (!response.ok) {
          throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;

      const contentDisposition = response.headers.get('Content-Disposition');
      var filename = contentDisposition
          ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
          : 'download.xlsx';
      filename = decodeURI(filename.split("; filename")[0]);
      console.log(filename);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const statusMap = {
    0: 'Closed',
    1: 'Open'
  };
  
  const getFormattedStatus = (status) => {
    return statusMap[status] || 'Unknown';
  };

  const StatusCell = ({ value }) => {
    const color = value === 0 ? 'rgb(221, 24, 24)' : 'green';
    const border = value === 0 ? 'transparent' : '1px solid green';
    const fontWeight = value === 0 ? '450' : '650';
    return (
      <div style={{ color, border, textAlign: 'center', fontWeight, borderRadius: '12px', padding: '5px 5px',  width: '90px',}}>
        {getFormattedStatus(value)}
      </div>
    );
  };
  

  const actionColumns = [
    {
      field: "action",
      headerName: "Action",
      width: 280,
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className="cellAction">
            <div className="approveButton" onClick={() => updateDataOpen(row.id)}>
            Open
            </div>
            <div className="rejectButton" onClick={() => updateDataClose(row.id)}>
            Closed
            </div>
          </div>
        );
      },
    },
    {
      field: "export",
      headerName: "Export to Excel",
      width: 170,
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className="exportButton3" onClick={() => exportData(row.id)}>Export</div>
        );
    },
    },
    {
      field: "inputScore",
      headerName: "Input Score",
      width: 170,
      renderCell: (params) => {
        const detailUrl = `/adminlogin/classgroupingdb/${params.row.id}`; 
        return (
          <Link to={detailUrl} className="linkdetails-db3">
            <button className="detailsButton3">Input</button>
          </Link>
        );
      },
    },
  ];

 const columns = [
    { field: 'nama_kelas', headerName: 'Periode', width: 280 },
    { field: 'regis_status', headerName: 'Status', width: 120, renderCell: (params) => <StatusCell value={params.row.regis_status} /> },
  ];

  const [newClass, setNewClass] = useState("");

  return (
    <div className="body">
      {loading ? (
        <div className="LoadingScreen">
          <i>Loading Data <div className="loader"></div></i>
        </div>
      ) : (
      <div>
      <div className="judulDB3">
        <b>| <i>Periode Pendaftaran</i></b>
        <div className="enterclassdb3" >
        <input
          type="text"
          placeholder="Enter New Period"
          value={newClass}
          onChange={(e) => setNewClass(e.target.value)}
        /></div>
        <button onClick={() => handleAddClass(newClass)} className="addclass3">Add Period</button>
      </div>
      <div className="datatableClassGroupingDB3">
        <DataGrid
          
          getRowId= {(rows)=>rows.id}
          rows={rows}
          columns={columns.concat(actionColumns)}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 8 },
            },
          }}
          pageSizeOptions={[8]}
          
        />
      </div>
      <MenuBar />
      </div>
      )}
    </div>
  );
}