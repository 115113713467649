import "./UserLogin.css";
import HeaderLog from "./HeaderLog";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Cookies from 'js-cookie';

export default function UserLogin() {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [nim, setNim] = useState("");
  const [tgl_lahir, setTgl_lahir] = useState("");
  const userRef = useRef();
  const errRef = useRef();
  const usenavigate=useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const apiUrlToep = process.env.REACT_APP_API_URL_TOEP;
  // const apiUrl = process.env.REACT_APP_API_URL_SIAKAD;

  useEffect(() => {
    userRef.current.focus();
}, [])

useEffect(() => {
    setErrMsg('');
}, [nim])

const authenticate = async (e) => {
  e.preventDefault();

  try {
    const response1 = await fetch(`${apiUrlToep}/mhs/info/${nim}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (!response1.ok) {
      throw new Error("Error fetching student data");
    }

    const data1 = await response1.json();

    if (data1.message === "Success") {
      let redirectPath;
      switch (data1.data.status) {
        case "proses":
          redirectPath = `/userlogin/inprogres/${nim}`;
          break;
        case "tertolak":
          redirectPath = '/userlogin/rejectuser';
          break;
        case "terdaftar":
          redirectPath = '/userlogin/inverif';
          break;
        default:
          break;
      }

      if (redirectPath) {
        usenavigate(redirectPath);
        return; 
      }
    } else {
    }

    const response2 = await fetch(`${apiUrlToep}/get_siakad/${nim}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tgl_lahir: tgl_lahir,
      }),
    });

  
    if (!response2.ok) {
      throw new Error("Login failed!");
    }

    const data2 = await response2.json();

    if (data2) {
      usenavigate(`/userlogin/detailprofile/${nim}`, {
        state: {
          nim1: nim,
          nama1: data2.data.nama,
          prodi1: data2.data.prodi,
          angkatan1: data2.data.angkatan,
          tgl_lahir1: data2.data.tgl_lahir,
        },
      });
    }

  } catch (err) {
    if (!err?.response) {
      setErrMsg('Login Failed');
      console.error(err)
    } else if (err.response?.status === 400) {
      setErrMsg('Login Failed');
    } else if (err.response?.status === 401) {
      setErrMsg('Unauthorized');
    } else {
      setErrMsg('Login Failed');
    }
    errRef.current.focus();
  }
};

  return (
    <div className="body">
      <HeaderLog />
      <section className="bg-userLogin">
        <div className="containerUserLog">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <form className="formuser" onSubmit={authenticate}>
            <h1>STUDENT LOGIN</h1>
            <div className="garisuser"></div>

            <div className="container-User">
              <label>
                <b>NIM </b>
              </label>
              <input
                ref={userRef}
                type="text"
                inputMode="numeric"
                placeholder="NIM"
                name="nim"
                value={nim}
                onChange={(e) => setNim(e.target.value)}
                required
              />

              <label>
                <b>Tanggal Lahir </b>
              </label>
              <input
                ref={userRef}
                type="date"
                // inputMode="date"
                placeholder="Tanggal Lahir"
                name="tgl_lahir"
                value={tgl_lahir}
                onChange={(e) => setTgl_lahir(e.target.value)}
                className="box-tgllahir"
                required
              />

              <div className="chaptcha">
                <ReCAPTCHA
                  sitekey="6LeDkngqAAAAAB_ZXWeRwx3OHnkVC1ATECg_pbd3"
                  onChange={(value) => {
                    setRecaptchaToken(value);
                  }}
                />
              </div>

              <div className="remember">
                <input type="checkbox" name="remember" /> Remember me
              </div>
              <div>
                <button className="buttonLogin" disabled={!recaptchaToken}>
                  Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <div className="margin"></div>
    </div>
  );
}
