import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import "./AcceptUsersDB.css";
import MenuBar from "./MenuBar";
import NotFound from "../NotfoundPage/NotFound";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';


export default function AcceptUsersDB() {
  const [rows, setRows] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [period, setPeriod] = useState([]);
  const params = useParams();
  const usenavigate=useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `http://127.0.0.1:8000/api/periode/acc/${params.id}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: Cookies.get("token"),
            },
          }
        );

        if (!response.ok) {
          setNotFound(true);
          return;
        }

        const data = await response.json();
        setRows(data.data);
        setPeriod(data.periode);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchOptions = async () => {
      try {
        const response = await fetch("http://127.0.0.1:8000/api/kelas", {
          headers: {
            'Accept': 'application/json',
            'Authorization': Cookies.get('token'),
          },
          withCredentials: true, 
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setOptions(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchOptions();
    fetchData();
  }, [params.id]);

  if (notFound) {
    return <NotFound />;
  }

  const handleChange = (event) => {
    setSelectedId(event.target.value);
  };

  const handleNavigate = () => {
    if (selectedId) {
      usenavigate(`/adminlogin/acceptusersdb/${selectedId}`); 
      window.location.reload();
    }
  }

  const rejectRowStatus = async(mhs_id) => {
    const setStatus = await fetch(`http://127.0.0.1:8000/api/mhs/status/${mhs_id}`, {
      method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
          status: 'tertolak',
        })
    });

    if(!setStatus.ok){
      throw new Error('Failed to Reject User: ' + setStatus.statusText);
    }

    window.location.reload();
  };

  const actionColumns = [
    {
      field: "action",
      headerName: "Action",
      width: 215,
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className="cellAction">
            <div className="rejectButton" onClick={() => rejectRowStatus(row.mhs_id, "Rejected")}>
              Reject
            </div>
          </div>
        );
      },
    },
  ];

  const columns = [
    { field: "nim", headerName: "NIM", width: 150 },
    { field: "nama", headerName: "Nama", width: 320 },
    { field: "prodi", headerName: "Prodi", width: 220 },
  ];


  return (
    <div className="body">
       {loading ? (
        <div className="LoadingScreen">
          <i>Loading Data<div className="loader"></div></i>
        </div>
      ) : (
      <div>
      <div className="judulDB2">
        <b>| <i>Peserta Terdaftar</i></b>
        <p className="textIdInput">
              <i>{period.nama_kelas}</i>
        </p>
      </div>
      <div>
        <select value={selectedId} onChange={handleChange} className="dropdown-db1">
          <option value="">Select an option</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.nama_kelas}
            </option>
          ))}
        </select>
        <button onClick={handleNavigate} disabled={!selectedId} className="btnDD-db1">
          Go to Details
        </button>
      </div>
      <div className="datatable">
         <DataGrid
              getRowId={(rows) => rows.nim}
              rows={rows}
              columns={columns.concat(actionColumns)}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 8 },
                },
              }}
              pageSizeOptions={[8]}
            />
      </div>
      <MenuBar />
      </div>
       )}
    </div>
  );
}