import "./home.css"
import Header from "./Header"
import { Link } from "react-router-dom"
import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';

export default function Home() {
  const [rows, setRows] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;
  
  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/contact`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
        },
        withCredentials: false,
      });
      
      if(!response.ok){
        throw new Error('failed to fetch data');
      }

      const data = await response.json();
      setRows(data[0]);
    } catch(error) {
      console.error('Error fetching data :', error)
    }
  };

    return(
    <div className="body">
        <Header />
        <section className='bg-Home'>
        <div className='container'>
          <div className='row'>
            <p><b>Website Pendaftaran TOEP UNIKAMA</b></p>
            <h1>TOEP</h1>
            <p>Test of English Proficiency (TOEP) adalah tes <br />kemahiran berbahasa Inggris dalam konteks <br />akademik yang diselenggarakan secara daring <br />menggunakan komputer multimedia.</p>
          </div>
        </div>
      </section>
      <div className='marginhome'></div>
      <div className="whatsapp-btn-container">
        <Link className="whatsapp-btn" to={rows.contact} target="_blank"><i><img src='/whatsapp.png' alt='contact us' className="btn-contactus"/></i></Link>
        <span>Contact Us</span>
    </div>
    </div>);
}