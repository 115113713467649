import "./Profile.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';

export default function Profile() {
  // set const
  // const [mhs, setMhs] = useState([]);
  const [rows, setRows] = useState([]);
  const [period, setPeriod] = useState([]);
  const [nim, setNim] = useState("");
  const [nama, setNama] = useState("");
  const [prodi, setProdi] = useState("");
  const [angkatan, setAngkatan] = useState("");
  const [tgl_lahir, setTglLahir] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const usenavigate=useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;
  // const apiUrlSiakad = process.env.REACT_APP_API_URL_SIAKAD;
  const {state} = useLocation();
  const { nim1 = '', nama1 = '', prodi1 = '', angkatan1 = '', tgl_lahir1 = '' } = state || {};

  useEffect(() => {
    // Periksa apakah semua data yang dibutuhkan ada
    const hasAllData = nim1 && nama1 && prodi1 && angkatan1 && tgl_lahir1;

    if (!hasAllData) {
      usenavigate('/userlogin'); // Arahkan ke halaman login jika data tidak lengkap
      Swal.fire({
        title: "!Error",
        icon: "error",
        text: ".Login Gagal. Silakan login kembali",
      });
    }
  }, [nim1, nama1, prodi1, angkatan1, tgl_lahir1, usenavigate]);


  useEffect(() => {
    const detail = async () => {
      try {
        // const response = await fetch(`${apiUrl}/get_siakad/${params.nim}`,
        // {
        //   method: "GET",
        // }
        // );

        const check = await fetch(`${apiUrl}/periode/active`,
          {
            headers: {
              "Content-Type": "application/json", 
              Accept: "application/json",
            },
          }
        );

        // if (!response.ok) {
        //   throw new Error("Login failed!");
        // }

        if (!check.ok) {
          throw new Error("Periode Not Active");
        }

        const periode = await check.json();
        const per = periode.data;
        setPeriod(per);

        // const data = await response.json();
        // setMhs(data.data);
        setLoading(false);
        
      } catch (error) {
        usenavigate('/userlogin');
        Swal.fire({
          title: "!Error",
          icon: "error",
          text: "Login Failed",
        });
      }
    }
    detail();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/contact`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
        },
        withCredentials :false,
      });

      if(!response.ok){
        throw new Error('failed to fetch data');
      }

      const data = await response.json();
      setRows(data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data :', error)
    }
  };

  const updatedData = ({
    nim: nim || nim1,
    nama: nama || nama1,
    prodi: prodi || prodi1,
    angkatan: angkatan || angkatan1,
    tgl_lahir: tgl_lahir || tgl_lahir1,
  });

  const addMhs = () => {
    fetch(`${apiUrl}/mhs/store`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        updatedData
      ),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        Swal.fire({
          title: "Selamat! Anda telah terdaftar",
          icon: "success",
          text: "Data dalam proses verifikasi, Mohon lakukan pengecekan secara berkala untuk melihat status verifikasi",
          confirmButtonText: 'Lanjutkan'
        }).then((result) => {
          if (result.isConfirmed) {
            usenavigate(`/userlogin/inprogres/${params.nim}`);
          }
        });
      })
      .catch((error) => {
        console.error("Error Add Data :", error);
        Swal.fire({
          title: "!Error",
          icon: "error",
          text: "Terjadi kesalahan saat mendaftar. Pastikan periode pendaftaran aktif atau pastikan belum pernah mendaftar dalam periode yang sama",
        });
      });
  };
  
  const Alert = () => {
    Swal.fire({
      title: "?Apakah Anda yakin",
      text: ".Pastikan data diri Anda sudah benar",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Batal",
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Lanjut Daftar",
    }).then((result) => {
      if (result.isConfirmed) {
        addMhs();
      }
    });
  };

  return (
    <div className="body">
      {loading ? (
        <div className="LoadingScreen">
          <i>Loading Data <div className="loader"></div></i>
        </div>
      ) : (
      <div>
      <section className="bg-Profile">
        <div className="whatsapp-btn-container">
          <Link
            className="whatsapp-btn"
            to={rows.contact}
            target="_blank"
          >
            <i>
              <img
                src="/whatsapp.png"
                alt="contact us"
                className="btn-contactus"
              />
            </i>
          </Link>
          <span>Contact Us</span>
        </div>
        <div className="containerProfile">
          <div className="wrapper-1">
            <h1>TOEP UNIKAMA <div className="periodeTxt"> {period?.[0]?.nama_kelas || 'Ditutup'}</div></h1>
            <div className="garis"></div>
          </div>
          <div className="wrapper-2">
            <h1>
              <b>MY PROFILE</b>
            </h1>
            <form className="formProfile">
              <div className="flex">
                <div className="inputBox">
                  <div className="container-nama">
                    <span className="span-nama">
                      <b>Nama <i className="updateDataUser">*</i></b>
                    </span>
                    <input
                      type="text"
                      name="update_nama"
                      defaultValue={nama1}
                      onChange={(e) => setNama(e.target.value)}
                      className="box-nama"
                    />
                  </div>
                  <div className="grid-1">
                    <div className="container-nim">
                      <span className="span-nim">
                        <b>NIM</b>
                      </span>
                      <input
                        type="text"
                        name="update_nim"
                        defaultValue={nim1}
                        readOnly="readonly"
                        onChange={(e) => setNim(e.target.value)}
                        className="box-nim"
                      />
                    </div>
                    <div className="container-prodi">
                      <span className="span-prodi">
                        <b>Prodi</b>
                      </span>
                      <input
                        type="text"
                        name="update_prodi"
                        readOnly="readonly"
                        defaultValue={prodi1}
                        onChange={(e) => setProdi(e.target.value)}
                        className="box-prodi"
                      />
                    </div>
                  </div>
                  <div className="grid-2">
                    <div className="container-angkatan">
                      <span className="span-angkatan">
                        <b>Angkatan</b>
                      </span>
                      <input
                        type="text"
                        name="update_angkatan"
                        readOnly="readonly"
                        defaultValue={angkatan1}
                        onChange={(e) => setAngkatan(e.target.value)}
                        className="box-angkatan"
                      />
                    </div>
                    <div className="container-tgllahir">
                      <span className="span-tgllahir">
                        <b>Tanggal Lahir</b>
                      </span>
                      <input
                        type="date"
                        name="update_tgllahir"
                        readOnly="readonly"
                        defaultValue={tgl_lahir1}
                        onChange={(e) => setTglLahir(e.target.value)}
                        className="box-tgllahir"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <i className="keteranganUpdate">* Menunjukkan Data yang bisa diperbarui apabila data tidak sesuai.</i>
            </form>
            <div className="container-btn">
              <Link to="/">
              <button type="button" className="btn-back">
                  <b>KEMBALI</b>
              </button>
              </Link>
              <button
                type="button"
                className="btn-daftar"
                onClick={Alert}
              >
                <b>DAFTAR</b>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="margin"></div>
      </div>
      )}
    </div>
  );
}
