import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import "./ClassGroupingDB.css";
import MenuBar from "./MenuBar";
import NotFound from "../NotfoundPage/NotFound";
import Cookies from 'js-cookie';

export default function ClassGroupingDB() {
  const [rows, setRows] = useState([]);
  const [period, setPeriod] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const params = useParams();
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/periode/acc/${params.id}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: Cookies.get("token"),
            },
          }
        );

        if (!response.ok) {
          setNotFound(true);
          return;
        }

        const data = await response.json();
        setRows(data.data);
        setPeriod(data.periode);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [params.id]);

  if (notFound) {
    return <NotFound />;
  }

  const handleChange = (event, params) => {
    const { name, value } = event.target;

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.mhs_id === params.row.mhs_id ? { ...row, [name]: value } : row
      )
    );
  };

  const handleSave = async () => {
  try {
    const updatedData = rows.map((row) => ({
        mhs_id: String(row.mhs_id),
        learning: row.learning !== undefined ? Number(row.learning) : Number(row.nilai?.learning) || 0,
        reading: row.reading !== undefined ? Number(row.reading) : Number(row.nilai?.reading) || 0,
        structure: row.structure !== undefined ? Number(row.structure) : Number(row.nilai?.structure) || 0,
        total_score: row.total_score !== undefined ? Number(row.total_score) : Number(row.nilai?.total_score) || 0,
      }));
    
    const response = await fetch(`${apiUrl}/nilai/update`, {
      method: "PATCH",
      headers: {
        'Accept': 'application/json',
        'Content-Type': "application/json",
        Authorization: Cookies.get("token"),
      },
      body: JSON.stringify({ "data": updatedData }),
    });

    if (!response.ok) {
      console.error("Error saving data");
      return;
    }
    window.location.reload();
  } catch (error) {
    console.error("Error saving data:", error);
  }
};

  const actionColumns = [
    {
      field: "learning",
      headerName: "Listening",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="inputscoredb4">
            <input
              type="number"
              name="learning"
              id="learning"
              autoComplete="off"
              defaultValue={params.row.nilai?.learning} 
              onChange={(event) => handleChange(event, params)}
            />
          </div>
        );
      },
    },
    {
      field: "structure",
      headerName: "Structure",
      width: 120,
      renderCell: (params) => (
        <div className="inputscoredb4">
          <input
            type="number"
            name="structure"
            id="structure"
            autoComplete="off"
            defaultValue={params.row.nilai?.structure}
            onChange={(event) => handleChange(event, params)}
          />
        </div>
      ),
    },
    {
      field: "reading",
      headerName: "Reading",
      width: 120,
      renderCell: (params) => (
        <div className="inputscoredb4">
          <input
            type="number"
            name="reading"
            id="reading"
            autoComplete="off"
            defaultValue={params.row.nilai?.reading}
            onChange={(event) => handleChange(event, params)}
          />
        </div>
      ),
    },
    {
      field: "total_score",
      headerName: "Total Score",
      width: 120,
      renderCell: (params) => (
        <div className="inputscoredb4">
          <input
            type="number"
            name="total_score"
            id="total_score"
            autoComplete="off"
            onChange={(event) => handleChange(event, params)}
            defaultValue={params.row.nilai?.total_score}
          />
        </div>
      ),
    },
  ];

  const columns = [
    { field: "nim", headerName: "NIM", width: 150 },
    { field: "nama", headerName: "Nama", width: 250 },
    { field: "prodi", headerName: "Prodi", width: 150 },
  ];

  return (
    <div className="body">
      {loading ? (
        <div className="LoadingScreen">
          <i>Loading Data<div className="loader"></div></i>
        </div>
      ) : (
        <div className="classGrouping">
          <div className="judulDB3">
            <p>
              <b>| <i>Input Score </i></b>
            </p>
            <p className="textIdInput">
              <i>{period.nama_kelas}</i>
            </p>
          </div>
          <button className="savedb4" onClick={handleSave}>
            SIMPAN
          </button>
          <div className="datatableClassGroupingDB">
            <DataGrid
              getRowId={(rows) => rows.mhs_id}
              rows={rows}
              columns={columns.concat(actionColumns)}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 8 },
                },
              }}
              pageSizeOptions={[8]}
            />
          </div>
          <MenuBar />
        </div>
      )}
    </div>
  );
}
