import "./AcceptUsersDB.css";
import MenuBar from "./MenuBar";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function AcceptUsersDB() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState("");
  const [link_grup, setLink] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/contact`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
        },
        withCredentials :false,
      });

      if(!response.ok){
        throw new Error('failed to fetch data');
      }

      const data = await response.json();
      setRows(data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data :', error)
    }
  };

  const updatedData = ({
    contact: contact || rows.contact,
    link_grup: link_grup || rows.link_grup
  });

  const updateData = () => {
    fetch(`${apiUrl}/contact/1`, {
      method: "PATCH",
      headers: {
        Accept : "application/json",
        "Content-Type": "application/json",
        Authorization: Cookies.get("token"),
      },
      body: JSON.stringify(updatedData),
    })
    .then((response) => {
      if(!response.ok){
        throw new Error('Update data Error');
      }
      window.location.reload();
      return response.json();
    })
    .catch((error) => {
      console.error("Error Update Data :", error);
    });
  };
  

  return (
    <div className="body">
      {loading ? (
        <div className="LoadingScreen">
          <i>Loading Data <div className="loader"></div></i>
        </div>
      ) : (
      <div>
      <div className="containerContact">
        <div className="Contact"><img src="/BG-Contact.png" alt="bg-Contact" className="bgContact"/></div>
        <div className="Contact2">
          <h1 className="h1-Contact"> Ubah Contact</h1>
          <h5>Masukkan Link URL Contact WA</h5>
                    <div className="container-contact">
                      <span className="span-contact">
                        <b>Contact Person</b>
                      </span>
                      <input
                        type="text"
                        defaultValue={rows.contact}
                        onChange={(e) => setContact(e.target.value)}
                        name="update_contact"
                         className="box-contact"
                      />
                    </div>
                    <div className="container-grup">
                      <span className="span-grup">
                        <b>Grup TOEP</b>
                      </span>
                      <input
                        type="text"
                        defaultValue={rows.link_grup}
                        onChange={(e) => setLink(e.target.value)}
                        name="update_contact"
                         className="box-grup"
                      />
                    </div>
                  <button
                    onClick={updateData}
                    type="button"
                    className="btn-add">
                    <b>UBAH</b>
                  </button>
                  <p className="btn-add3"><b>Coba Link</b></p>
                  <div className="span-cobaLink">
                    <Link
                      to={rows.contact}
                      className="btn-add2"
                      target="_blank">
                      <b>Contact Us</b>
                    </Link>
                    <h1 className="btn-add2"><b>I</b></h1>
                    <Link
                      target="_blank"
                      to={rows.link_grup}
                      className="btn-add2">
                      <b>Grup Whatsapp</b>
                    </Link>
                  </div>
        </div>
      </div>
      <MenuBar />
      </div>
       )}
    </div>
  );
}