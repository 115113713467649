import "./InVerif.css"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

export default function InVerif() {
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/contact`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
        },
        withCredentials :false,
      });

      if(!response.ok){
        throw new Error('failed to fetch data');
      }

      const data = await response.json();
      setRows(data[0]);
    } catch (error) {
      console.error('Error fetching data :', error)
    }
  };

    return(
    <div className="body">
        <section className='bg-InVerif'>
        <div className='container-InVerif'>
          <div className='row-InVerif'>
            <h1>Anda dapat mengikuti TOEP</h1>
            <p><i>Data Anda berhasil diverifikasi</i></p>
          </div>
          <div className="container-btnjoin">
          <Link to={rows.link_grup} target="_blank"><button type="button" className="btn-join"><b>Gabung Grup WA</b></button></Link>
        </div>
        </div>
      </section>
      <div className='margin-InVerif'></div>
    </div>);
}